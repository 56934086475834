/*
 * Global Beat COVID stylesheet
 *
 * Site-wide styles, base typography, etc go here.
 *
 * We can use CSS modules for per-component styling.
 * https://nextjs.org/docs/basic-features/built-in-css-support
 */

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  color: #444;
  margin: 0;
}

.grecaptcha-badge {
  visibility: collapse !important;
}

.adminTable {
  border-collapse: collapse;
  margin: 1em 0;
}
.adminTable th {
  background-color: #f0f0f0;
  padding: 5px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.adminTable td {
  background-color: #fff;
  cursor: pointer;
  padding: 5px;
}
.adminTable tr:nth-child(even) td {
  background-color: #f0f0f0;
}
.adminTable tr:hover td {
  background-color: #e0e0ff;
}
